import {
  AppBar,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { CancelOutlined, CheckCircleOutlined, Close } from "@material-ui/icons";
import arrayMutators from "final-form-arrays";
import { forwardRef, Ref, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { AccountAndHelpButtons } from "../../common/AccountAndHelpButtons";
import { isAdmin } from "../../config";
import { AdebisFormType } from "./AdebisForm";
import { DiscardDialog } from "./DiscardDialog";
import { GaFormType } from "./GaForm";
import { createSetting, selectSettingCreate } from "./settingCreateSlice";
import { SettingForm } from "./SettingForm";

type SettingCreateForm = AdebisFormType | GaFormType;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
      backgroundColor: isAdmin ? "#f3e5d4" : "#f5f5f5",
      color: "#202020",
    },
    statusSwitch: {
      paddingRight: theme.spacing(3),
    },
  }),
);

const Transition = forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: Ref<unknown>,
  ) => {
    return <Slide {...props} direction="left" ref={ref} />;
  },
);

function getDestinations(alertDestinations: string) {
  return Array.from(
    new Set(
      alertDestinations.split(",").map((destination) => destination.trim()),
    ),
  ).map((destination) => ({ mailAddress: `${destination}@opt.ne.jp` }));
}

function getOwners(settingOwners: string) {
  return Array.from(
    new Set(settingOwners.split(",").map((owner) => owner.trim())),
  ).map((owner) => ({ userId: owner }));
}

export function SettingCreate() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectSettingCreate);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const open = pathname === "/settings/new" || pathname === "/settings/new/";

  const handleClose = () => {
    setOpenDiscardDialog(false);
    history.push("/settings");
  };

  const handleDiscardDialogOpen = () => {
    setOpenDiscardDialog(true);
  };

  const handleDiscardDialogClose = () => {
    setOpenDiscardDialog(false);
  };

  const createGaSetting = (values: GaFormType) => {
    const {
      name,
      amsToolId,
      amsToolAccountId,
      dataAcquisitionStatus,
      masterId,
      settingOwners,
      alertDestinations,
      viewId,
      propertyId,
      isGa360,
      gaCvs,
      gaDimensions,
      gaFilters,
      authorizationCode,
    } = values;

    dispatch(
      createSetting({
        name,
        amsToolId,
        amsToolAccountId,
        dataAcquisitionStatus,
        masterId,
        settingOwners: getOwners(settingOwners),
        alertDestinations: getDestinations(alertDestinations),
        gaSetting: {
          viewId: Number(viewId),
          propertyId: Number(propertyId),
          isGa360: Boolean(isGa360),
          authorizationCode,
          gaCvs,
          gaDimensions,
          gaFilters,
        },
      }),
    );
  };

  const createAdebisSetting = (values: AdebisFormType) => {
    const {
      name,
      amsToolId,
      amsToolAccountId,
      dataAcquisitionStatus,
      masterId,
      settingOwners,
      alertDestinations,
      adebisCvs,
      isNewUi,
    } = values;

    dispatch(
      createSetting({
        name,
        amsToolId,
        amsToolAccountId,
        dataAcquisitionStatus,
        masterId,
        settingOwners: getOwners(settingOwners),
        alertDestinations: getDestinations(alertDestinations),
        adebisSetting: {
          adebisCvs,
          isNewUi: Boolean(isNewUi),
        },
      }),
    );
  };

  const handleSubmit = (values: SettingCreateForm) => {
    if (values.amsToolId === 23) {
      createGaSetting(values);
    } else if (values.amsToolId === 9) {
      createAdebisSetting(values);
    } else {
      throw new Error("不明なフォームデータが入力されました。");
    }
  };

  return (
    <>
      <DiscardDialog
        open={openDiscardDialog}
        handleClose={handleDiscardDialogClose}
        handleSubmit={handleClose}
      />
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <Form<SettingCreateForm>
          onSubmit={(values) => handleSubmit(values)}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            hasValidationErrors,
            pristine,
            values: { amsToolId },
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Tooltip title="CV連携設定の作成をキャンセル">
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleDiscardDialogOpen}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={3}>
                      <Typography variant="h6">
                        {"CV連携設定新規作成" + (isAdmin ? "｜管理画面" : "")}
                      </Typography>
                    </Grid>
                    {amsToolId ? (
                      <Grid item xs={12} sm={8}>
                        <Field<boolean>
                          name="dataAcquisitionStatus"
                          type="checkbox"
                          defaultValue={true}
                          render={({ input }) => {
                            const dataAcquisitionStatus = input.checked
                              ? "有効"
                              : "無効";

                            const color = input.checked ? "primary" : "default";
                            const icon = input.checked ? (
                              <CheckCircleOutlined />
                            ) : (
                              <CancelOutlined />
                            );

                            return (
                              <span className={classes.statusSwitch}>
                                <Tooltip
                                  title={"朝の自動取得ステータスを切り替え"}
                                >
                                  <Switch
                                    {...input}
                                    color="primary"
                                    disabled={status === "loading"}
                                  />
                                </Tooltip>
                                <Chip
                                  label={`ステータス：${dataAcquisitionStatus}`}
                                  color={color}
                                  size="small"
                                  icon={icon}
                                />
                              </span>
                            );
                          }}
                        />
                        <Tooltip title="CV連携設定を作成">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={
                              pristine ||
                              hasValidationErrors ||
                              status === "loading"
                            }
                            disableFocusRipple={true}
                            startIcon={<CheckCircleOutlined />}
                            endIcon={
                              status === "loading" ? (
                                <CircularProgress size={24} />
                              ) : undefined
                            }
                          >
                            設定する
                          </Button>
                        </Tooltip>
                      </Grid>
                    ) : undefined}
                  </Grid>
                  <AccountAndHelpButtons />
                </Toolbar>
              </AppBar>

              <SettingForm {...{ amsToolId, push }} />
            </form>
          )}
        />
      </Dialog>
    </>
  );
}
