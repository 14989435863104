export type MasterType = {
  id: number;
  name: string;
};

export const masterTypes: MasterType[] = [
  { id: 1, name: "Google Analytics" },
  { id: 4, name: "Google Analytics（ディメンション選択）" },
  { id: 5, name: "Google Analytics 4" },
  { id: 2, name: "AD EBiS（媒体種別粒度）" },
  { id: 3, name: "AD EBiS（広告ID粒度）" },
];
