import {
  AppBar,
  Button,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
  CancelOutlined,
  CheckCircleOutlined,
  Close,
  DeleteOutlined,
} from "@material-ui/icons";
import arrayMutators from "final-form-arrays";
import { forwardRef, Ref, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AccountAndHelpButtons } from "../../common/AccountAndHelpButtons";
import { isAdmin } from "../../config";
import { AdebisFormType } from "./AdebisForm";
import { DeleteDialog } from "./DeleteDialog";
import { DiscardDialog } from "./DiscardDialog";
import { GaFormType } from "./GaForm";
import { SettingForm } from "./SettingForm";
import { fetchSettingShow, selectSettingShow } from "./settingShowSlice";
import { selectSettingUpdate, updateSetting } from "./settingUpdateSlice";

type SettingUpdateForm = AdebisFormType | GaFormType;

interface Params {
  id?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      paddingTop: theme.spacing(5),
    },
    appBar: {
      position: "relative",
      backgroundColor: isAdmin ? "#f3e5d4" : "#f5f5f5",
      color: "#202020",
    },
    statusSwitch: {
      paddingRight: theme.spacing(3),
    },
  }),
);

const Transition = forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: Ref<unknown>,
  ) => {
    return <Slide {...props} direction="left" ref={ref} />;
  },
);

function getDestinations(alertDestinations: string) {
  return Array.from(
    new Set(
      alertDestinations.split(",").map((destination) => destination.trim()),
    ),
  ).map((destination) => ({ mailAddress: `${destination}@opt.ne.jp` }));
}

function getOwners(settingOwners: string) {
  return Array.from(
    new Set(settingOwners.split(",").map((owner) => owner.trim())),
  ).map((owner) => ({ userId: owner }));
}

export function SettingUpdate() {
  const classes = useStyles();
  const params = useParams<Params>();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectSettingUpdate);
  const { status: showStatus, response: showResponse } =
    useSelector(selectSettingShow);

  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const id = Number(params.id);
  const open =
    pathname === `/settings/${id}/edit` || pathname === `/settings/${id}/edit/`;

  const initialValues = (): Record<string, never> | SettingUpdateForm => {
    if (!showResponse) {
      return {};
    } else if (showResponse.amsToolId === 23) {
      const {
        dataAcquisitionStatus,
        name,
        amsToolId,
        amsToolAccountId,
        gaSetting: {
          viewId,
          propertyId,
          isGa360,
          gaCvs,
          gaDimensions,
          gaFilters,
          authorizationCode,
        },
        masterId,
        settingOwners,
        alertDestinations,
      } = showResponse;

      return {
        dataAcquisitionStatus,
        name,
        amsToolId,
        amsToolAccountId,
        viewId: viewId,
        propertyId: propertyId,
        isGa360: isGa360 ? 1 : 0,
        gaCvs,
        gaDimensions,
        gaFilters,
        authorizationCode,
        // NOTE: GET /masters を叩くより先に masterId をセットするために以下のような警告が出るが、無視して良い
        // Material-UI: You have provided an out-of-range value `0` for the select (name="masterId") component. Consider providing a value that matches one of the available options or ''. The available values are "".
        masterId,
        settingOwners: settingOwners.map((owner) => owner.userId).join(","),
        alertDestinations: alertDestinations
          .map((destination) => destination.mailAddress.slice(0, -10))
          .join(","),
      } as GaFormType;
    } else if (showResponse.amsToolId === 9) {
      const {
        dataAcquisitionStatus,
        name,
        amsToolId,
        amsToolAccountId,
        adebisSetting: { adebisCvs, isNewUi },
        masterId,
        settingOwners,
        alertDestinations,
      } = showResponse;

      return {
        dataAcquisitionStatus,
        name,
        amsToolId,
        amsToolAccountId,
        adebisCvs,
        isNewUi: isNewUi ? 1 : 0,
        // NOTE: GET /masters を叩くより先に masterId をセットするために以下のような警告が出るが、無視して良い
        // Material-UI: You have provided an out-of-range value `0` for the select (name="masterId") component. Consider providing a value that matches one of the available options or ''. The available values are "".
        masterId,
        settingOwners: settingOwners.map((owner) => owner.userId).join(","),
        alertDestinations: alertDestinations
          .map((destination) => destination.mailAddress.slice(0, -10))
          .join(","),
      } as AdebisFormType;
    } else {
      throw new Error("API から不明なレスポンスが取得されました。");
    }
  };

  const handleClose = () => {
    setOpenDiscardDialog(false);
    history.push("/settings");
  };

  const handleDiscardDialogOpen = () => {
    setOpenDiscardDialog(true);
  };

  const handleDiscardDialogClose = () => {
    setOpenDiscardDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const updateGaSetting = (values: GaFormType) => {
    const {
      name,
      amsToolId,
      amsToolAccountId,
      dataAcquisitionStatus,
      masterId,
      settingOwners,
      alertDestinations,
      viewId,
      propertyId,
      isGa360,
      gaCvs,
      gaDimensions,
      gaFilters,
      authorizationCode,
    } = values;

    dispatch(
      updateSetting({
        id,
        name,
        amsToolId,
        amsToolAccountId,
        dataAcquisitionStatus,
        masterId,
        settingOwners: getOwners(settingOwners),
        alertDestinations: getDestinations(alertDestinations),
        gaSetting: {
          viewId: Number(viewId),
          propertyId: Number(propertyId),
          isGa360: Boolean(isGa360),
          gaCvs,
          gaDimensions,
          gaFilters,
          authorizationCode,
        },
      }),
    );
  };

  const updateAdebisSetting = (values: AdebisFormType) => {
    const {
      name,
      amsToolId,
      amsToolAccountId,
      dataAcquisitionStatus,
      masterId,
      settingOwners,
      alertDestinations,
      adebisCvs,
      isNewUi,
    } = values;

    dispatch(
      updateSetting({
        id,
        name,
        amsToolId,
        amsToolAccountId,
        dataAcquisitionStatus,
        masterId,
        settingOwners: getOwners(settingOwners),
        alertDestinations: getDestinations(alertDestinations),
        adebisSetting: {
          adebisCvs,
          isNewUi: Boolean(isNewUi),
        },
      }),
    );
  };

  const handleSubmit = (values: SettingUpdateForm) => {
    if (values.amsToolId === 23) {
      updateGaSetting(values);
    } else if (values.amsToolId === 9) {
      updateAdebisSetting(values);
    } else {
      throw new Error("不明なフォームデータが入力されました。");
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchSettingShow({ id }));
    }
  }, [dispatch, open, id]);

  return (
    <>
      <DiscardDialog
        open={openDiscardDialog}
        isEdit={true}
        handleClose={handleDiscardDialogClose}
        handleSubmit={handleClose}
      />
      <DeleteDialog
        open={openDeleteDialog}
        id={id}
        handleClose={handleDeleteDialogClose}
      />
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <Form<SettingUpdateForm>
          initialValues={initialValues()}
          onSubmit={(values) => handleSubmit(values)}
          mutators={{ ...arrayMutators }}
          render={({
            handleSubmit,
            hasValidationErrors,
            pristine,
            values: { amsToolId },
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item>
                      <Tooltip title="CV連携設定の編集をキャンセル">
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={
                            pristine ? handleClose : handleDiscardDialogOpen
                          }
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={10} sm={3}>
                      <Typography variant="h6">
                        {"CV連携設定編集" + (isAdmin ? "｜管理画面" : "")}
                      </Typography>
                    </Grid>
                    {amsToolId ? (
                      <>
                        <Grid item>
                          <Field<boolean>
                            name="dataAcquisitionStatus"
                            type="checkbox"
                            defaultValue={true}
                            render={({ input }) => {
                              const dataAcquisitionStatus = input.checked
                                ? "有効"
                                : "無効";

                              const color = input.checked
                                ? "primary"
                                : "default";

                              const icon = input.checked ? (
                                <CheckCircleOutlined />
                              ) : (
                                <CancelOutlined />
                              );

                              return (
                                <span>
                                  <Tooltip
                                    title={"朝の自動取得ステータスを切り替え"}
                                  >
                                    <Switch
                                      {...input}
                                      color="primary"
                                      disabled={status === "loading"}
                                    />
                                  </Tooltip>
                                  <Chip
                                    label={`ステータス：${dataAcquisitionStatus}`}
                                    color={color}
                                    size="small"
                                    icon={icon}
                                  />
                                </span>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip title="CV連携設定を更新">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={
                                pristine ||
                                hasValidationErrors ||
                                status === "loading"
                              }
                              disableFocusRipple={true}
                              startIcon={<CheckCircleOutlined />}
                              endIcon={
                                status === "loading" ? (
                                  <CircularProgress size={24} />
                                ) : undefined
                              }
                            >
                              設定する
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={
                              isAdmin
                                ? "管理画面では削除できません"
                                : "CV連携設定を削除"
                            }
                          >
                            <Button
                              disabled={status === "loading"}
                              disableFocusRipple={true}
                              startIcon={<DeleteOutlined />}
                              onClick={
                                isAdmin ? undefined : handleDeleteDialogOpen
                              }
                            >
                              削除
                            </Button>
                          </Tooltip>
                        </Grid>
                      </>
                    ) : undefined}
                  </Grid>
                  <AccountAndHelpButtons />
                </Toolbar>
              </AppBar>

              {showStatus === "idle" || showStatus === "loading" ? (
                <Grid
                  className={classes.progress}
                  container
                  justifyContent="center"
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <SettingForm {...{ amsToolId, push, isEdit: true }} />
              )}
            </form>
          )}
        />
      </Dialog>
    </>
  );
}
